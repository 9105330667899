import * as React from "react"
import { Link, graphql } from "gatsby"
import { RichTextRenderer } from '../components/rich-text-renderer'
import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoPlayer from "../components/videoPlayer"
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '../../node_modules/@splidejs/react-splide/dist/css/splide.min.css'


const PostTemplate = ({ data, location, video }) => {
  const post = data.webiny.getPost.data
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data.webiny

  const videoSrc = {
    type: "video",
    sources: [
      {
        src: post.video,
        type: 'video/mp4',
        size: 1080,
      }
    ]
  };

  const options = {
    controls: true,
    sources: [{ src: post.video }]
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.title}
        description={post.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
      <div className="container mx-auto px-5">
        {post.video ? <VideoPlayer autoPlay options={options} /> : null }
      </div>
      
      <div className="bg-white">
        <div className="container mx-auto text-black">
            <div className="p-5 inline-block">
              <h1 itemProp="headline" className="knockoutFont text-4xl lg:text-6xl uppercase">{post.title}</h1>
              <div itemProp="articleBody" className="ProximaNova font-normal">
                <RichTextRenderer content={post.bodyText} />
              </div>
              <div className="grid grid-cols-2 gap-5">
                <div className="">
                  {next.data[0] && (
                      <div className="bg-zinc-900 rounded-md">
                        <Link to={`/${next.data[0].slug}`} rel="next">
                          <button className="rounded-lg w-full p-2 inline-block transition duration-300 ease-out hover:ease-in hover:bg-neon-green hover:outline-black">
                            <video 
                                poster={`${next.data[0].image}`}
                                loop muted playsInline 
                                className="h-full w-full rounded-xl drop-shadow-card hover:drop-shadow-none"
                              >
                              <source
                                src={`${next.data[0].video}`}
                                type="video/mp4"
                              />
                            </video>
                            <h2 className="text-xs leading-normal text-left p-2 font-semibold">
                                <span itemProp="headline" className="">{next.data[0].title} <Link to="about" itemProp="url" className="items-center justify-center text-base font-medium text-white hover:text-black hover:ease-in hover:text-neon-green hover:outline-black hover:after:content-arrowNeonGreen after:content-arrowBlack after:inline-block after:w-8 after:pl-2" /></span>
                            </h2>
                          </button>
                        </Link>
                      </div>
                    )}
                </div>
                <div className="">
                  {previous.data[0] && (
                      <div className="bg-zinc-900 rounded-md">
                        <Link to={`/${previous.data[0].slug}`} rel="prev">
                          <button className="rounded-lg w-full p-2 inline-block transition duration-300 ease-out hover:ease-in hover:bg-neon-green hover:outline-black">
                            <video 
                                poster={`${previous.data[0].image}`}
                                loop muted playsInline 
                                className="h-full w-full rounded-xl drop-shadow-card hover:drop-shadow-none"
                              >
                              <source
                                src={`${previous.data[0].video}`}
                                type="video/mp4"
                              />
                            </video>
                            <h2 className="text-xs leading-normal text-left p-2 font-semibold">
                                <span itemProp="headline" className="">{previous.data[0].title} <Link to="about" itemProp="url" className="items-center justify-center text-base font-medium text-white hover:text-black hover:ease-in hover:text-neon-green hover:outline-black after:content-arrowBlack after:inline-block after:w-8 after:pl-2" /></span>
                            </h2>
                          </button>
                        </Link>
                      </div>
                    )}
                </div> 
              </div>
              {/* <Splide 
                options={ {
                  pagination: false,
                  perPage: 2,
                  arrows: false,
                  gap: 10
                } }
                >
                <SplideSlide>
                <div className="">
                    {previous.data[0] && (
                      <div className="bg-zinc-900 rounded-md p-5">
                        <Link to={`/${previous.data[0].slug}`} rel="prev">
                          <button>
                            <img src={`${previous.data[0].image}`} width="200px" className="w-36 h-28" />
                            <p className="text-left min-w-0">← {previous.data[0].title}</p>
                          </button>
                        </Link>
                      </div>
                    )}
                </div>
                </SplideSlide>
                <SplideSlide>
                  <div className="">
                      {next.data[0] && (
                        <div className="bg-zinc-900 rounded-md p-5">
                          <Link to={`/${next.data[0].slug}`} rel="next">
                            <button>
                              <img src={`${next.data[0].image}`} width="200px" className="w-36 h-30" />
                              <p className="text-left min-w-0">{next.data[0].title} →</p>
                            </button>
                          </Link>
                        </div>
                      )}
                  </div>
                </SplideSlide>
              </Splide>  */}
            </div>
        </div>
      </div>

      </article>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query PostBySlug(
      $slug: String
      $id: ID
      $createdOn: Webiny_DateTime
    ) {
    site {
      siteMetadata {
        title
      }
    }
    webiny {
      getPost(where: {slug: $slug}) {
        data {
          id
          title
          slug
          description
          bodyText
          createdOn
          image
          video
          visibility
        }
      }
      next: listPost(limit: 1, where: {createdOn_gt: $createdOn, id_not: $id }) {
        data {
          title
          slug
          video
          image
        }
      }
      previous: listPost(limit: 1, where: {createdOn_lt: $createdOn, id_not: $id }) {
        data {
          title
          slug
          video
          image
        }
      }
    } 
  }
`
